import { useEffect } from 'react'
import nProgress from 'nprogress'
import './styles.less'

function AppLoading() {
  useEffect(() => {
    nProgress.start()
    return nProgress.done
  }, [])
}

export default AppLoading
