import { parseColorHex } from './helper'

const RDKit = (() => {
  let promise
  return () => {
    if (!promise) {
      promise = new Promise((resolve, reject) => {
        window
          .initRDKitModule()
          .then((instance) => {
            console.log('RDKit Version: ' + instance.version())
            resolve(instance)
          })
          .catch(() => reject())
      })
    }
    return promise
  }
})()

export const GenerateSVG = async (
  input,
  width,
  height,
  highlight,
  atoms = []
) => {
  const rdkit = await RDKit()
  const mol = rdkit.get_mol(input)
  const svg = mol.get_svg_with_highlights(
    JSON.stringify({
      width: width ?? 400,
      height: height ?? 400,
      bondLineWidth: 1.5,
      addStereoAnnotation: true,
      backgroundColour: [1, 1, 1, 0],
      includeRadicals: false,
      highlightRadius: 0.7,
      atoms: atoms.map((i) => parseInt(i)),
      highlightColour: parseColorHex(highlight),
    })
  )
  mol.delete()
  return svg
}

export default RDKit
