import { Suspense, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { Router } from 'common/router'
import zhCN from 'antd/es/locale/zh_CN'
import RDKit from 'common/rdkit'
import AppLoading from 'components/AppLoading'
import routes from 'common/routes'

function resolve(routes = []) {
  return routes.map((route, index) => {
    let element = undefined
    if (route.lazy) element = <route.lazy />
    if (route.redirect) element = <Navigate to={route.redirect} />
    return (
      <Route
        key={index}
        path={route.path}
        index={route.index}
        element={element}
        children={route.children ? resolve(route.children) : undefined}
      />
    )
  })
}

function App() {
  useEffect(() => {
    RDKit()
  }, [])
  return (
    <ConfigProvider locale={zhCN}>
      <Suspense fallback={<AppLoading />}>
        <Router>
          <Routes>{resolve(routes)}</Routes>
        </Router>
      </Suspense>
    </ConfigProvider>
  )
}

export default App
