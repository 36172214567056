import moment from 'moment'

export function formatSize(bytes, decimals = 2, spacer = ' ') {
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  if (bytes === 0) return '0' + spacer + sizes[0]
  const dm = decimals < 0 ? 0 : decimals
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + spacer + sizes[i]
}

export function formatUnixTime(time) {
  return time && moment.unix(time).format('YYYY-MM-DD HH:mm:ss')
}

export function formatDuration(duration) {
  const hours = Math.trunc(duration / 3600)
    .toString()
    .padStart(2, '0')
  const minutes = (Math.trunc(duration / 60) % 60).toString().padStart(2, '0')
  const seconds = (Math.trunc(duration) % 60).toString().padStart(2, '0')
  return [hours, minutes, seconds].join(':')
}

export function parseOptions(options = {}) {
  return Object.entries(options).map(([value, text]) => ({ value, text }))
}

export function parseColorHex(color = '') {
  if (color.length !== 7) return undefined
  return [
    color.substring(1, 3),
    color.substring(3, 5),
    color.substring(5, 7),
  ].map((hex) => parseFloat((parseInt(hex, 16) / 255).toFixed(2)))
}
