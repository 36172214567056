import { lazy } from 'react'

const resolve = (routes = []) => {
  for (const route of routes) {
    if (route.children) route.children = resolve(route.children)
    if (route.component)
      route.lazy = lazy(() => import(`../${route.component}`))
  }
  return routes
}

const calculate = [
  { index: true, redirect: 'tasks' },
  { path: 'tasks', component: 'components/CalculateTasks' },
  { path: 'task', component: 'components/CalculateResult' },
  { path: 'submit', component: 'components/CalculateSubmit' },
  { path: 'submit/success', component: 'components/CalculateSubmit/Success' },
]

const database = [
  { index: true, redirect: 'pka' },
  { path: 'pka', component: 'components/Database/LexPkaQuery' },
  { path: 'drug', component: 'components/Database/DrugDB' },
  { path: 'drug/search', component: 'components/Database/DrugDB/search' },
  { path: 'drug/detail', component: 'components/Database/DrugDB/detail' },
]

const profile = [
  { index: true, redirect: 'password' },
  { path: 'password', component: 'components/Profile/Password' },
]

const layout = [
  { index: true, redirect: 'calculate/tasks' },
  { path: 'calculate', children: calculate },
  { path: 'database', children: database },
  { path: 'molecule/editor', component: 'components/Tools/MolEditor' },
  { path: 'molecule/2d', component: 'components/Tools/SmilesToStructure' },
  { path: 'molecule/3d', component: 'components/Tools/MoleculeViewer' },
  { path: 'demo/pl-complex', component: 'components/Demo/PLComplex' },
  { path: 'profile', children: profile },
  { path: '*', component: 'components/NotFound' },
]

export default resolve([
  { path: 'login', component: 'components/Login' },
  { path: 'logout', component: 'components/Logout' },
  { children: layout, component: 'components/AppLayout' },
])
